import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import styles from "./PrivateLayout.module.scss";
import Header from "./Header.tsx";
import SideBar from "./SideBar";
import { useNavigate } from "react-router";
import { useLazyQuery } from "@apollo/client";
import { LOGIN_ME } from "src/graphql/query";
import {
  getRole,
  getToken,
  removeRole,
  removeToken,
} from "src/components/Common/Utils";
import { ROUTES } from "src/constant";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

interface Props {
  children: React.ReactNode;
}

const PrivateLayout: React.FC<Props> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State for sidebar visibility
  const navigate = useNavigate();
  const [LoginMe] = useLazyQuery(LOGIN_ME);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev); // Toggle sidebar visibility
  };

  useEffect(() => {
    const checkToken = async () => {
      const token = getToken();
      if (!token) {
        navigate(ROUTES.LOGIN);
      } else {
        const response = await LoginMe();
        if (
          response?.data?.loginMeForAdmin?.user?.role === "Admin" ||
          response?.data?.loginMeForAdmin?.user?.role === "SuperAdmin"
        ) {
          if (response?.data?.loginMeForAdmin?.user?.role === getRole()) {
            const pathname = window.location.pathname;
            Cookies.set(
              "permissions",
              JSON.stringify(
                response?.data?.loginMeForAdmin?.privacy?.permissions
              ),
              {
                secure: true,
                sameSite: "Strict",
                expires: 1,
              }
            );
            navigate(pathname);
          } else {
            removeRole();
            removeToken();
            navigate("/login");
            toast.warning("don't do data misleading");
          }
        } else {
          toast.warning("Unauthorized access");
          removeToken();
          removeRole();
          navigate(ROUTES.LOGIN);
        }
      }
    };

    checkToken();
  }, [LoginMe, navigate]);

  return (
    <Box sx={{ display: "flex", width: "100%", overflow: "hidden" }}>
      <CssBaseline />
      <Header />
      {/* Sidebar */}
      <Box display={"flex"}>
        <SideBar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </Box>
      {/* Main Content */}
      <Box
        className={`${styles.childrenContainer} ${
          isSidebarOpen ? "" : styles.sidebarClosed
        }`}
        sx={{
          flexGrow: 1,
          // marginLeft: isSidebarOpen ? "240px" : "64px", // Sidebar width adjustment
          transition: "margin-left 0.3s", // Smooth transition
          padding: "16px", // Consistent padding
          overflowX: "hidden", // Prevent overflow
          // width: isSidebarOpen ? `calc(100% - 240px)` : `calc(100% - 64px)`, // Adjust width
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PrivateLayout;
