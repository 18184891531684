import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomButton from "src/components/CustomButton";
import CustomTextField from "src/components/CustomTextfield";
import CustomTypography from "src/components/CustomTypography";
import AuthHeader from "./AuthHeader";
import styles from "./SignIn.module.scss";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SET_USERS } from "src/store/Action/AuthActions/SetUserAction";
import { toast } from "react-toastify";
import { ROUTES } from "src/constant";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import {
  getToken,
  navigateBasedOnPermissions,
  removeRole,
  removeToken,
  setToken,
} from "src/components/Common/Utils";
import Cookie from "js-cookie";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976d2",
      },
    },
    typography: {
      fontFamily: "Roboto, Arial, sans-serif",
    },
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingTop: "40px",
            paddingBottom: "40px",
          },
        },
      },
    },
  });

  const handleSignInClick = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await dispatch(SET_USERS(email, password));

      if (res?.signInByAdmin?.statusCode === 200) {
        const accessToken = res.signInByAdmin?.accessToken;

        setToken(accessToken);
        // Set cookie with options
        Cookie.set("role", res?.signInByAdmin?.user?.role, {
          secure: true,
          sameSite: "Strict",
          expires: 1,
        });
        Cookie.set("accessToken", accessToken, {
          secure: true,
          sameSite: "Strict",
          expires: 1,
        });
        Cookie.set(
          "permissions",
          JSON.stringify(res?.signInByAdmin?.privacy?.permissions),
          {
            secure: true,
            sameSite: "Strict",
            expires: 1,
          }
        );

        if (!res?.signInByAdmin?.privacy?.permissions?.length) {
          removeToken();
          removeRole();
          throw new Error(
            "You don't have access of admin panel. Contact Admin"
          );
        }
        toast.success(res?.signInByAdmin?.message);
        navigateBasedOnPermissions(navigate, 1000);
      } else {
        throw new Error(res?.signInByAdmin?.message);
      }
    } catch (error: any) {
      toast.error(error?.message || "Error signing in");
      setError(error?.message || "An error occurred during sign in.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      navigate(ROUTES.EVENTS);
      window.location.reload();
    }
  }, [navigate]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className={styles.formContainer}>
          <AuthHeader headerText="Welcome To Hi Surat" />
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <div className={styles.textfield}>
              <CustomTextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                placeholder="Enter your email"
                fullWidth
              />
              <CustomTextField
                label="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                placeholder="Enter your password"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff color="primary" />
                        ) : (
                          <Visibility color="primary" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {error && (
                <FormHelperText error style={{ marginTop: "10px" }}>
                  {error}
                </FormHelperText>
              )}
              <CustomButton
                className={styles.signInBtnWrapper}
                onClick={handleSignInClick}
                disabled={loading}
                fullWidth
              >
                <CustomTypography
                  variant="body2"
                  className={styles.btnTextWrapper}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Sign In"
                  )}
                </CustomTypography>
              </CustomButton>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
