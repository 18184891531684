import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Grid,
  InputAdornment,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { debounce } from "src/utils/debounce";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  ADD_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
} from "src/graphql/mutations";
import { GET_SUB_CATEGORY, GET_SUBCATEGORY } from "src/graphql/query";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getRole,
  hasPermissionPage,
  uploadImage,
  uploadImages,
} from "../Common/Utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import backButton from "../../Images/backButton.svg";
import DeleteModel from "src/components/Common/DeleteModel";
import JsonValidator from "../JsonValidator/JsonValidator";
import ToggleSwitch from "src/components/Common/ToggleSwitch";
import SaveIcon from "@mui/icons-material/Save";
import { UPDATE_SUB_CATEGORY_RANK } from "src/graphql/mutations";
import { ClearIcon } from "@mui/x-date-pickers";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { DELETE_MULTIPLE_SUBCATEGORIES } from "src/graphql/DeleteMutation";
interface SubCategory {
  _id: string;
  subCategoryName: string;
}

interface BannerImage {
  imageUrl: string;
  redirectLink: string;
  Title: string;
  Description: string;
}
interface TitleItem {
  platform: string;
  isEnabled: boolean;
  title: string;
}
interface FormValues {
  subCategoryName: string;
  description: string;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  imageUrl: string | File | null;
  additionalSubCategories: any[];
  focus: string;
  title: TitleItem[];
}

const SubCategory: React.FC = () => {
  const [list, setList] = useState<SubCategory[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [categoryName, setCategoryName] = useState<string>("");
  const [bannerImages, setBannerImages] = useState<BannerImage[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [subCategoryId, setSubCategoryId] = useState<string | null>(null);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 50 });
  const [isValidJson, setIsValidJson] = useState<boolean | null>(null);
  const [errors, setErrors] = useState<any>({});
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [rankUpdates, setRankUpdates] = useState<{
    [key: string]: number | null;
  }>({});
  const [isRankEdit, setIsRankEdit] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [DeleteMultipleSubCategory] = useMutation(
    DELETE_MULTIPLE_SUBCATEGORIES
  );
  const [UpdateSubCategoriesRanksBulk] = useMutation(UPDATE_SUB_CATEGORY_RANK);
  const [AddSubCategory] = useMutation(ADD_SUB_CATEGORY);
  const [deleteCategory] = useMutation(DELETE_SUB_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_SUB_CATEGORY);
  const [openDeleteMultipleModel, setOpenDeleteMultipleModel] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const client = useApolloClient();
  const { id } = useParams<{ id: string }>();
  const isSuperAdmin = getRole() === "SuperAdmin";

  const validationSchema = Yup.object().shape({
    subCategoryName: Yup.string().required("Sub Category Name is required"),
    description: Yup.string().required("Description is required"),
    metaTitle: Yup.string().required("Meta Title is required"),
    metaKeywords: Yup.string().required("Meta Keywords is required"),
    metaDescription: Yup.string().required("Meta Description is required"),
    focus: Yup.string().required("Focus Keywords is required"),
  });

  //Form initialize
  const formik = useFormik<FormValues>({
    initialValues: {
      subCategoryName: "",
      description: "",
      metaTitle: "",
      metaKeywords: "",
      metaDescription: "",
      focus: "",
      imageUrl: "",
      title: [
        { platform: "IOS", isEnabled: true, title: "" },
        { platform: "Android", isEnabled: true, title: "" },
      ],
      additionalSubCategories: [
        {
          subCategoryName: [],
        },
      ],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => handleSubmit(values),
  });

  //Initialize table data
  const COLUMNS = [
    ...(isSuperAdmin
      ? [
          {
            headerName: (
              <Box display={"flex"} alignItems={"center"}>
                <Checkbox
                  checked={
                    selectedRows.length === list.length && list.length > 0
                  }
                  indeterminate={
                    selectedRows.length > 0 && selectedRows.length < list.length
                  }
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </Box>
            ),
            field: "select",
            flex: 0.5,
            sortable: false,
            filterable: false,
            disableClickEventBubbling: true,
            renderCell: ({ row }: any) => (
              <Checkbox
                checked={selectedRows.includes(row?._id as never)}
                onChange={() => handleRowSelect(row._id)}
              />
            ),
          },
        ]
      : []),
    {
      headerName: "Sub Category Name",
      field: "subCategoryName",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        return (
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => handleRowClick(row?._id)}
          >
            {row?.subCategoryName}
          </Box>
        );
      },
    },
    {
      headerName: "Image",
      field: "imageUrl",
      flex: 1,
      renderCell: ({ row }: { row: any }) => (
        <Box display="flex" justifyContent="start" alignItems="center">
          {row.imageUrl ? (
            <img
              src={row.imageUrl}
              alt={row.categoryName}
              style={{ width: 40, height: 40, borderRadius: "50%" }}
            />
          ) : (
            <Typography variant="body2" color="textSecondary">
              No Image
            </Typography>
          )}
        </Box>
      ),
    },
    { headerName: "Description", field: "description", flex: 1 },
    {
      headerName: "Rank",
      field: "rank",
      flex: 1,
      renderCell: ({ row }: { row: any }) => {
        const [rankValue, setRankValue] = useState(row.rank || "");

        const handleRankChange = (value: string) => {
          setRankValue(value);
          setRankUpdates((prev) => ({
            ...prev,
            [row._id]: value === "" ? null : Number(value),
          }));
        };

        return isRankEdit ? (
          <TextField
            value={rankValue}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => handleRankChange(e.target.value)}
            placeholder="Enter Rank"
            variant="outlined"
            size="small"
            style={{ marginRight: "8px" }}
          />
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            style={{ height: "100%" }}
          >
            {rankValue}
          </Typography>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box>
          {hasPermissionPage("SubCategory", "update") && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(row);
              }}
              aria-label="edit"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <EditIcon />
            </IconButton>
          )}
          {hasPermissionPage("SubCategory", "delete") && (
            <IconButton
              aria-label="delete"
              style={{ color: "#00C5B9" }}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteId(row._id);
                setOpenDeleteModal(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_SUB_CATEGORY, {
    variables: {
      _id: id,
      subCategoryPage: pagination.page,
      subCategoryLimit: pagination.pageSize,
      search: "",
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleRowSelect = (id: any) => {
    setSelectedRows((prevSelectedRows: any) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId: any) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      const allRowIds = list.map((row: any) => row._id);
      setSelectedRows(allRowIds as any);
    } else {
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    if (data?.getCategoryByIdWithPaginatedSubcategories?.data) {
      setList(
        data?.getCategoryByIdWithPaginatedSubcategories?.data?.subCategory
      );
      setCategoryName(
        data?.getCategoryByIdWithPaginatedSubcategories?.data?.categoryName
      );
      refetch();
    }
  }, [data]);

  //handle change form values
  const customHandleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.handleChange(e);
    const { name, value } = e.target;
    setList((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  //Edit Subcategory
  const handleEdit = async (rowData: { _id: string }) => {
    try {
      const { data } = await client.query({
        query: GET_SUBCATEGORY,
        variables: { _id: rowData?._id },
        fetchPolicy: "network-only",
      });
      const subCategoryData = data?.getSubCategory?.data;

      if (subCategoryData) {
        formik.setValues({
          description: subCategoryData?.description || "",
          metaDescription: subCategoryData?.metaDescription || "",
          metaKeywords: subCategoryData?.metaKeywords || "",
          metaTitle: subCategoryData?.metaTitle || "",
          focus: subCategoryData?.focus || "",
          subCategoryName: subCategoryData?.subCategoryName || "",
          imageUrl: subCategoryData?.imageUrl || "",
          additionalSubCategories: subCategoryData?.additionalSubCategories || {
            subCategoryName: [],
          },
          title: subCategoryData?.title || [
            { platform: "IOS", isEnabled: true, title: "" },
            { platform: "Android", isEnabled: true, title: "" },
          ],
        });
      }

      setSubCategoryId(subCategoryData?._id);

      // Remove _typname from bannerImages
      const cleanBannerImages = (subCategoryData?.bannerImages || []).map(
        ({ __typename, ...rest }: any) => rest
      );
      setBannerImages(cleanBannerImages);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching subcategory data:", error);
    }
  };

  //Delete subcategory
  const handleDelete = async (id: string) => {
    try {
      await deleteCategory({
        variables: { _id: id },
      });
      toast.success("Subcategory deleted successfully!");
      setOpenDeleteModal(false);
      refetch();
    } catch (error) {
      toast.error("Error deleting subcategory.");
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const res = await DeleteMultipleSubCategory({
        variables: {
          _id: selectedRows,
        },
      });
      if (res?.data?.deleteMultipleSubCategory?.statusCode === 200) {
        toast.success(res?.data?.deleteMultipleSubCategory?.message);
        refetch();
      } else {
        throw new Error(res?.data?.deleteMultipleSubCategory?.message);
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message);
    } finally {
      setOpenDeleteMultipleModel(false);
    }
  };
  //Pagination
  const handlePageChange = async (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
    refetch({
      search: searchTerm ?? "",
      page: pagination.page,
      limit: pagination.pageSize,
    });
  };

  //Row click
  const handleRowClick = (id: string) => {
    navigate(`/subcategory/${id}`);
  };

  const handlePageSizeChange = (newPageSize: number) => {};

  //Banner Image function
  const handleBannerImageChange = (
    index: number,
    file: File | null,
    redirectLink: string,
    Title: string,
    Description: string
  ) => {
    const updatedBannerImages = [...bannerImages];
    const imageUrl = file
      ? URL.createObjectURL(file)
      : updatedBannerImages[index]?.imageUrl || "";
    updatedBannerImages[index] = { imageUrl, redirectLink, Title, Description };
    setBannerImages(updatedBannerImages);
  };

  const handleAddBannerImage = () => {
    setBannerImages([
      ...bannerImages,
      { imageUrl: "", redirectLink: "", Title: "", Description: "" },
    ]);
  };

  const handleRemoveBannerImage = (index: number) => {
    const updatedBannerImages = [...bannerImages];
    updatedBannerImages.splice(index, 1);
    setBannerImages(updatedBannerImages);
  };

  //Submit response
  const handleSubmit = async (values: FormValues) => {
    try {
      // Validate the form values using Yup validation schema
      await validationSchema.validate(
        { ...formik.values },
        { abortEarly: false }
      );

      // Prepare files for upload by converting blob URLs to files
      const filesToUpload = bannerImages.map((banner) => {
        const fileUrl = banner.imageUrl;
        if (fileUrl.startsWith("blob")) {
          return fetch(fileUrl)
            .then((res) => res.blob())
            .then((blob) => new File([blob], "image", { type: blob.type }))
            .catch((error) => {
              console.error("Error converting blob to file:", error);
              return null;
            });
        }
        return null;
      });

      // Resolve all file upload promises
      const resolvedFiles = await Promise.all(filesToUpload);
      const filesToUploadFiltered = resolvedFiles.filter(Boolean) as File[];

      // Upload files if any new files exist
      const uploadResult = filesToUploadFiltered.length
        ? await uploadImages(
            filesToUploadFiltered,
            "subcategory",
            values?.subCategoryName
          )
        : [];
      let image = values.imageUrl;
      if (typeof values.imageUrl != "string") {
        const res = await uploadImage(
          values.imageUrl as any,
          "subcategory",
          values?.subCategoryName
        );
        image = res?.uploadImage?.url;
      }

      // Prepare the variables for API request
      const variables = {
        ...values,
        imageUrl: typeof image === "string" ? image : "",
        bannerImages: bannerImages.map((banner, index) => ({
          imageUrl:
            banner?.imageUrl ?? uploadResult?.uploadMultipleImages[index]?.url,
          redirectLink: banner.redirectLink,
          Title: banner.Title,
          Description: banner.Description,
        })),
      };
      // Update or add the subcategory based on existence
      if (subCategoryId) {
        const res = await updateCategory({
          variables: { _id: subCategoryId, input: variables },
        });
        if (res?.data?.updateSubCategory?.statusCode === 200) {
          toast.success(
            res?.data?.updateSubCategory?.message ??
              "Subcategory updated successfully!"
          );
          formik.resetForm();
        } else {
          throw new Error(res?.data?.updateSubCategory?.message as any);
        }
      } else {
        const res = await AddSubCategory({
          variables: {
            input: {
              ...variables,
              categoryId: id,
            },
          },
        });
        if (res?.data?.addSubCategory?.statusCode) {
          toast.success(
            res?.data?.addSubCategory?.message ??
              "Subcategory added successfully!"
          );
          setSubCategoryId(null);
          formik.resetForm();
        } else {
          throw new Error(res?.data?.addSubCategory?.message as any);
        }
      }
      setSubCategoryId(null);
      setOpenModal(false);

      refetch({
        variables: {
          page: pagination?.page,
        },
      });
      formik.resetForm();
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        setErrors(
          err.inner.reduce((acc, error) => {
            if (error.path) {
              acc[error.path] = error.message;
            }
            return acc;
          }, {} as any)
        );
      } else {
        console.error("An error occurred:", err);
        toast.error(
          err?.message ?? "An unexpected error occurred. Please try again."
        );
      }
    }
  };

  const removeImageHandler = () => {
    formik.setValues((prev) => ({
      ...prev,
      imageUrl: "",
    }));
  };

  const inputClickHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setValues((prev) => ({
      ...prev,
      imageUrl: e.target.files ? e.target.files[0] : null,
    }));
  };
  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      refetch({
        search: term,
        page: 1,
        limit: pagination.pageSize,
      });
    }, 1000),
    [refetch, pagination.pageSize] // Removed pagination.page as it's set to 1 in refetch call
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handleRankUpdate = async () => {
    const input = Object.keys(rankUpdates).map((id) => ({
      subCategoryId: id,
      rank: rankUpdates[id],
    }));

    try {
      const response = await UpdateSubCategoriesRanksBulk({
        variables: { input: { subcategories: input } },
      });
      if (response?.data?.updateSubCategoriesRanksBulk?.statusCode === 200) {
        toast.success(response.data.updateSubCategoriesRanksBulk.message);
        setRankUpdates({});
      } else {
        throw new Error(response.data.updateSubCategoriesRanksBulk.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      refetch();
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setPagination((prev) => ({ ...prev, page: 1 }));
    refetch({ search: "", page: 1, limit: pagination.pageSize });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return openModal ? (
    <form onSubmit={() => handleSubmit(formik.values)}>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={backButton}
              alt="backButton"
              height={25}
              width={25}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={() => {
                formik.resetForm();
                setOpenModal(false);
                setSubCategoryId(null);
              }}
            />
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              {categoryName}
            </Typography>
          </div>
        </Box>
        <Divider />

        <TextField
          margin="dense"
          label="SubCategory Name"
          name="subCategoryName"
          fullWidth
          value={formik.values.subCategoryName}
          onChange={customHandleChange}
          error={!!errors.subCategoryName}
          helperText={errors.subCategoryName}
        />
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          name="description"
          value={formik.values.description}
          onChange={customHandleChange}
          error={!!errors.description}
          helperText={errors.description}
        />
        {formik.values.title.map((item, index) => (
          <div key={index} style={{ marginBottom: "16px" }}>
            <TextField
              margin="dense"
              label={`${item.platform} Title`}
              fullWidth
              name={`title[${index}].title`}
              value={formik.values.title[index].title}
              onChange={formik.handleChange}
              error={formik.touched.title?.[index]?.title}
              helperText={formik.touched.title?.[index]?.title}
            />
          </div>
        ))}

        <TextField
          margin="dense"
          label="Meta Title"
          fullWidth
          name="metaTitle"
          value={formik.values.metaTitle}
          onChange={customHandleChange}
          error={!!errors.metaTitle}
          helperText={errors.metaTitle}
        />
        <TextField
          margin="dense"
          label="Meta Description"
          fullWidth
          name="metaDescription"
          value={formik.values.metaDescription}
          onChange={customHandleChange}
          error={!!errors.metaDescription}
          helperText={errors.metaDescription}
        />
        <TextField
          margin="dense"
          label="Meta Keywords"
          fullWidth
          name="metaKeywords"
          value={formik.values.metaKeywords}
          onChange={customHandleChange}
          error={!!errors.metaKeywords}
          helperText={errors.metaKeywords}
        />
        <TextField
          margin="dense"
          label="Focus Keyword"
          fullWidth
          name="focus"
          value={formik.values.focus}
          onChange={customHandleChange}
          error={!!errors.focus}
          helperText={errors.focus}
        />
        <Box width={"full"}>
          <JsonValidator
            isValidJson={isValidJson}
            jsonInput={formik.values.additionalSubCategories}
            setIsValidJson={setIsValidJson}
            setJsonInput={(newJson) => {
              formik.setValues((prev) => ({
                ...prev,
                additionalSubCategories: newJson,
              }));
            }}
          />
        </Box>
        <Divider style={{ margin: "16px 0" }} />
        <Typography variant="h6" gutterBottom>
          Image
        </Typography>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"center"}
        >
          <Grid item xs={12} md={4}>
            {typeof formik.values?.imageUrl === "string" &&
            formik.values?.imageUrl?.includes("https://") ? (
              <Box
                sx={{
                  width: { xs: "100%", sm: "300px" },
                  height: { xs: "200px", sm: "300px" },
                  marginY: "10px",
                  borderRadius: 2,
                  border: "2px dashed #00C5B9",
                  overflow: "hidden",
                  position: "relative",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <img
                  src={formik.values?.imageUrl}
                  alt="category"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    transition: "opacity 0.2s ease-in-out", // Smooth fade-in for the image
                  }}
                />
                <CustomButton
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={removeImageHandler}
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    backgroundColor: "#ff4d4d", // Red for remove action
                    opacity: 0.8,
                  }}
                >
                  Remove
                </CustomButton>
              </Box>
            ) : (
              <label htmlFor="category-image-upload">
                <Box
                  sx={{
                    width: "100%",
                    height: { xs: "200px", sm: "300px" }, // Adjust height for mobile and desktop
                    borderRadius: 2,
                    marginY: "10px",
                    border: "2px dashed #00C5B9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#f9f9f9", // Light background for the drop zone
                    transition: "background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#e8f7f5", // Slightly darker background on hover
                    },
                    overflow: "hidden",
                  }}
                >
                  {formik.values?.imageUrl ? (
                    <img
                      src={URL.createObjectURL(formik.values?.imageUrl as any)}
                      alt="category"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transition: "opacity 0.2s ease-in-out", // Smooth fade-in for image
                      }}
                    />
                  ) : (
                    <Typography
                      color="primary"
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" }, // Larger text on bigger screens
                        fontWeight: "bold",
                      }}
                    >
                      Upload Image
                    </Typography>
                  )}
                </Box>
              </label>
            )}
            <input
              type="file"
              onChange={inputClickHandler}
              style={{ display: "none" }}
              id="category-image-upload"
            />
          </Grid>
        </Grid>
        {/* Banner Images Section */}
        <Divider style={{ margin: "16px 0" }} />
        <Typography variant="h6" gutterBottom>
          Banner Images
        </Typography>

        {bannerImages.map((banner, index) => (
          <Grid container spacing={2} key={index} alignItems="flex-start">
            <Grid item xs={12} sm={6} md={3}>
              <input
                type="file"
                onChange={(e) =>
                  handleBannerImageChange(
                    index,
                    e.target.files?.[0] || null,
                    banner.redirectLink,
                    banner.Title,
                    banner.Description
                  )
                }
                style={{ display: "none" }}
                id={`banner-upload-${index}`}
              />
              <label htmlFor={`banner-upload-${index}`}>
                <Box
                  sx={{
                    width: "100%",
                    height: 200,
                    marginTop: "10px",
                    borderRadius: 2,
                    border: "2px dashed #00C5B9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                >
                  {banner.imageUrl ? (
                    <img
                      src={banner.imageUrl}
                      alt={`banner-${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Typography color="primary">Upload Image</Typography>
                  )}
                </Box>
              </label>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Redirect Link"
                fullWidth
                value={banner.redirectLink}
                onChange={(e) =>
                  handleBannerImageChange(
                    index,
                    null,
                    e.target.value,
                    banner.Title,
                    banner.Description
                  )
                }
                margin="normal"
              />
              <TextField
                label="Title"
                fullWidth
                value={banner.Title}
                onChange={(e) =>
                  handleBannerImageChange(
                    index,
                    null,
                    banner.redirectLink,
                    e.target.value,
                    banner.Description
                  )
                }
                margin="normal"
              />
              <TextField
                label="Description"
                fullWidth
                value={banner.Description}
                onChange={(e) =>
                  handleBannerImageChange(
                    index,
                    null,
                    banner.redirectLink,
                    banner.Title,
                    e.target.value
                  )
                }
                margin="normal"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              container
              justifyContent="flex-end"
              mt={3}
            >
              <CustomButton
                variant="outlined"
                color="secondary"
                onClick={() => handleRemoveBannerImage(index)}
              >
                Remove Image
              </CustomButton>
            </Grid>
          </Grid>
        ))}

        <CustomButton
          variant="outlined"
          onClick={handleAddBannerImage}
          style={{ marginTop: "16px" }}
        >
          Add Banner Image
        </CustomButton>

        <Box
          width={100}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginInline: "auto",
            marginTop: "16px",
          }}
        >
          <CustomButton
            onClick={() => handleSubmit(formik.values)}
            variant="contained"
          >
            Submit
          </CustomButton>
        </Box>
      </Box>
    </form>
  ) : (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleBackButtonClick}
          />
          <Typography variant="h4" style={{ color: "#00C5B9" }}>
            {categoryName}
          </Typography>
        </div>

        <Box display="flex" alignItems="center" gap={2}>
          <ToggleSwitch mode={isRankEdit} setMode={setIsRankEdit} />
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {isSuperAdmin && (
            <Box>
              <Tooltip title="Multiple Delete">
                <IconButton
                  aria-label="Multiple Delete"
                  style={{ color: "#ff0059" }}
                  onClick={() => setOpenDeleteMultipleModel(true)}
                >
                  <DeleteSweepIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          {isRankEdit && (
            <CustomButton
              onClick={handleRankUpdate}
              variant="contained"
              sx={{ width: 50 }}
            >
              <SaveIcon />
            </CustomButton>
          )}
          {hasPermissionPage("SubCategory", "add") && (
            <Box mr={4}>
              <CustomButton
                onClick={() => {
                  setSubCategoryId(null);
                  setOpenModal(true);
                }}
                variant="contained"
              >
                Create
              </CustomButton>
            </Box>
          )}
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={
            data?.getCategoryByIdWithPaginatedSubcategories?.data
              ?.subCategoryCount
          }
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <DeleteModel
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => deleteId && handleDelete(deleteId)}
        message="Are you sure you want to delete this deal?"
      />
      {openDeleteMultipleModel && (
        <DeleteModel
          open={openDeleteMultipleModel}
          onClose={() => {
            setOpenDeleteMultipleModel(false);
          }}
          onConfirm={handleMultipleDelete}
          message="Are you sure you want to delete all this multiple subcategories?"
        />
      )}
    </Box>
  );
};

export default SubCategory;
