import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_PRIVACY } from "src/graphql/query";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import CustomButton from "src/components/CustomButton";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";
import { DELETE_PRIVACY } from "src/graphql/DeleteMutation";
import { hasPermissionPage } from "src/components/Common/Utils";

const Permission: React.FC = () => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_PRIVACY);
  const [users, setUsers] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [privacyID, setPrivacyID] = useState<string | null>(null);
  const navigate = useNavigate();
  const [deletePrivacy] = useMutation(DELETE_PRIVACY, {
    onError: (error) => {
      // Handle and display GraphQL errors
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        const errorMessage = error.graphQLErrors
          .map((err: any) => err.message)
          .join(", ");
        console.error("GraphQL Error:", errorMessage);
        toast.error(
          errorMessage || "An error occurred while deleting privacy."
        );
      } else if (error.networkError) {
        // Handle network errors
        console.error("Network Error:", error.networkError);
        toast.error("A network error occurred.");
      } else {
        // Handle other errors
        console.error("Error:", error.message || error);
        toast.error(
          error.message || "An error occurred while deleting privacy."
        );
      }
    },
  });
  useEffect(() => {
    if (data?.getAllPrivacy?.data) {
      setUsers(data.getAllPrivacy.data);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id: string) => {
    setPrivacyID(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      if (privacyID) {
        const res = await deletePrivacy({
          variables: { _id: privacyID },
        });

        if (res.errors && res.errors.length > 0) {
          const errorMessage = res.errors[0].message;
          throw new Error(`Error: ${errorMessage}`);
        }
        if (res?.data?.deletePrivacy?.statusCode === 200) {
          toast.success(res?.data?.deletePrivacy?.message);
          setPrivacyID(null);
          setOpenConfirmDialog(false);
          refetch();
        }
      }
    } catch (error: any) {
      console.error("Error occurred:", error?.message);
      toast.error(
        error?.message || "An error occurred while deleting the privacy."
      );
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Box mb={4}>
        <Typography variant="h4" gutterBottom>
          User Permissions Management
        </Typography>
      </Box>

      {hasPermissionPage("Privacy", "add") && (
        <Box width={200} justifySelf={"end"}>
          <CustomButton
            variant="contained"
            color="primary"
            sx={{ marginBottom: 2 }}
            onClick={() => window.open("/permissions/add", "_blank")}
          >
            Add User Permissions
          </CustomButton>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: 18 }}>User</TableCell>
              <TableCell sx={{ fontSize: 18 }}>Email</TableCell>
              <TableCell sx={{ fontSize: 18 }}>Permissions</TableCell>
              <TableCell sx={{ fontSize: 18 }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((user: any) => (
                <TableRow key={user?._id}>
                  <TableCell>
                    {user.userId?.firstName} {user.userId?.lastName}
                  </TableCell>
                  <TableCell>{user.userId?.email}</TableCell>
                  <TableCell>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Page</TableCell>
                          <TableCell>View</TableCell>
                          <TableCell>Update</TableCell>
                          <TableCell>Verify</TableCell>
                          <TableCell>Delete</TableCell>
                          <TableCell>Add</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {user?.permissions?.map((permission: any) => (
                          <TableRow key={permission.type}>
                            <TableCell>{permission.type}</TableCell>
                            <TableCell>{permission.view ? "✔" : "✘"}</TableCell>
                            <TableCell>
                              {permission?.update ? "✔" : "✘"}
                            </TableCell>
                            <TableCell>
                              {permission?.verify ? "✔" : "✘"}
                            </TableCell>
                            <TableCell>
                              {permission?.delete ? "✔" : "✘"}
                            </TableCell>
                            <TableCell>{permission?.add ? "✔" : "✘"}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                      {hasPermissionPage("Privacy", "update") && (
                        <CustomButton
                          variant="outlined"
                          onClick={() => {
                            window.open(
                              `/permissions/edit/${user?._id}`,
                              "_blank"
                            );
                          }}
                        >
                          Edit
                        </CustomButton>
                      )}
                      {hasPermissionPage("Privacy", "delete") && (
                        <CustomButton
                          variant="outlined"
                          color="error"
                          onClick={() => handleDelete(user?._id)}
                        >
                          Delete
                        </CustomButton>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={users.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </TableContainer>
      <DeleteModel
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this privacy?"
      />
    </Box>
  );
};

export default Permission;
